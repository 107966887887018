import {
  CellClassParams,
  ColDef,
  GridApi,
  IAggFunc,
  IAggFuncParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { ViewColumn } from 'common/types/viewColumn';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ValueRenderer } from './ValueRenderer';
import { cellStyleFunction, headerClassFunction, isNullGrouping } from './helpers/computeAgColumn';
import { FeatureFlags } from 'common/feature_flags';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { useAutoGroupAttributes } from './customHooks/useAutoGroupAttributes';
import { useHandleExport, useInitializeExport } from './customHooks/exportHooks';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import eventBus from './helpers/EventBus';
import {
  generateCellClasses,
  generateExcelStyles,
  generateUrlValue,
  getCellClassRules
} from './helpers/TableExportFormatting';
import { Hierarchy, HierarchyColumnConfig, OrderConfig } from 'common/visualizations/vif';
import { ExportData } from './types';
import { FormatStyle } from 'common/authoring_workflow/reducers/types';
import { isNaN, isUndefined, maxBy, minBy } from 'lodash';
import { getAgTableHeaderName, getFieldName } from './helpers/TableColumnFormatting';
import { findColumnMetadata } from './customHooks/useColDefs';
import { NULL_GROUPING } from './Constants';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { SoQLType } from 'common/types/soql';

export interface IExportGridParams {
  domain: string;
  datasetUid: string;
  columns: ViewColumn[];
  removedHierarchyColumnNames: Set<string>;
  hierarchyColumns: HierarchyColumnConfig[];
  vifColumns: ViewColumn[];
  activeHierarchyId: string | undefined;
  agGridContext: any;
  headerFormat: FormatStyle;
  agGridReactAttributes: AgGridReactProps;
  hierarchyConfig?: Hierarchy;
  gridApi: GridApi | null;
  datasetName?: string;
  getExportData?: (selectedFiltered: boolean) => Promise<ExportData>;
  vizUid?: string;
  vifOrderConfig: OrderConfig[];
}

// exportMinFunc mimics the server-side behavior of MIN(string).
// By default, Ag-Grid does not provide min aggregation for strings.
// This also applies to exportMaxFunc
export const exportMinFunc = (params: IAggFuncParams) => {
  const { values } = params;
  return minBy(values, (str) => str);
};

export const exportMaxFunc = (params: IAggFuncParams) => {
  const { values } = params;
  return maxBy(values, (str) => str);
};

export const exportCountFunc = (params: IAggFuncParams) => {
  const { values } = params;

  // do not count undefined, empty string, and NaN values
  const acceptedValues = values.filter(
    (val) =>
      (typeof val == 'string' && val) || (typeof val == 'number' && !isNaN(val)) || typeof val == 'boolean'
  );
  return acceptedValues.length;
};

const ExportGrid = (props: IExportGridParams) => {
  const {
    domain,
    datasetUid,
    hierarchyConfig,
    columns,
    removedHierarchyColumnNames,
    hierarchyColumns,
    vifColumns,
    datasetName,
    getExportData,
    activeHierarchyId,
    vizUid,
    agGridContext,
    gridApi,
    headerFormat,
    agGridReactAttributes,
    vifOrderConfig
  } = props;
  const gridRef = useRef<AgGridReact<any>>(null);
  const [isExportTableRendered, setIsExportTableRendered] = useState<boolean>(false);
  const [rowsToBeExported, setRowsToBeExported] = useState<unknown[] | undefined>(undefined);
  const [currentExcelStyles, setCurrentExcelStyles] = useState([] as any);

  useEffect(() => {
    eventBus.on(`exportGridData-${vizUid}`, initializeExport);
    return () => {
      eventBus.remove(`exportGridData-${vizUid}`, initializeExport);
    };
  }, []);

  useEffect(() => {
    if (gridApi && gridRef) {
      const excelStyles = generateExcelStyles({
        columnFormats: agGridContext.current.columnFormats,
        rowStripeStyle: agGridContext.current.currentRowStripeStyle,
        columnMetadata: agGridContext.current.columnMetadata,
        headerFormat: agGridContext.current.headerFormat
      });
      setCurrentExcelStyles(excelStyles);
    }
  }, [
    agGridContext.current.columnFormats,
    agGridContext.current.currentRowStripeStyle,
    gridApi,
    headerFormat
  ]);

  const initializeExport = useInitializeExport({
    viewColumns: vifColumns,
    getExportData: getExportData,
    setIsExportTableRendered: setIsExportTableRendered,
    setRowsToBeExported: setRowsToBeExported
  });

  const handleExport = useHandleExport({
    gridRef,
    isExportTableRendered,
    rowsToBeExported,
    datasetName,
    setIsExportTableRendered,
    setRowsToBeExported,
    vifOrderConfig
  });

  const clientSideModules = [
    ClientSideRowModelModule,
    ExcelExportModule,
    RowGroupingModule,
    RangeSelectionModule,
    ClipboardModule,
    SideBarModule,
    ColumnsToolPanelModule,
    SetFilterModule,
    MultiFilterModule
  ];

  const exportColDefs = () => {
    const defs: ColDef[] = columns.map((column: ViewColumn) => {
      // to ag-grid, `number` will define the ordering of the group columns;
      // `null` means to clear the existing value (but can only be used if there _is_ an existing value)
      // `undefined` means that this isn't a row group (it also means don't change the existing value, if present)
      let rowGroupIndex: number | null | undefined;
      const shouldResetRowIndex = removedHierarchyColumnNames?.has(column.fieldName);
      const hierarchyConfigIdx = hierarchyColumns.findIndex(
        ({ columnName }) => columnName === column.fieldName
      );
      const localHierarchyConfig = hierarchyColumns[hierarchyConfigIdx];
      const hierarchyAggregation = localHierarchyConfig?.aggregation ?? null;
      const isColumnGrouping = localHierarchyConfig?.isGrouping ?? false;
      const isColumnHidden = (hierarchyConfig ? localHierarchyConfig?.hidden : column.hide) ?? false;
      const columnFormat = agGridContext.current?.columnFormats[column.fieldName];
      const viewColumnMetadata = findColumnMetadata(column, agGridContext.current?.columnMetadata) ?? column;
      const displayName = getAgTableHeaderName(columnFormat, viewColumnMetadata);
      if (shouldResetRowIndex) {
        rowGroupIndex = null;
      } else if (isColumnGrouping) {
        rowGroupIndex = hierarchyConfigIdx;
      }
      return {
        field: column.fieldName,
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data) return;
          const field = params.colDef.field ?? '';
          const value = params.data[field];
          const ctx = params.context as CustomAgGridContext;

          const { groupedColumns } = ctx;
          if (isNullGrouping(groupedColumns, value, field)) {
            return NULL_GROUPING;
          }

          if (column.dataTypeName == 'number') {
            return parseFloat(value);
          }

          const isTotalCell = params.node?.isRowPinned();
          const isDateValue =
            !isUndefined(value) && column.dataTypeName === SoQLType.SoQLFloatingTimestampT && !isTotalCell;
          if (isDateValue) {
            return value.split('.')[0];
          }

          // As of September 2024, we do not use hyperlinks for URLs,
          // if we are to use hyperlinks in the future just edit this condition
          // reference: https://www.ag-grid.com/react-data-grid/excel-export-hyperlinks/
          if (column.dataTypeName == 'url') {
            return generateUrlValue(value);
          }

          return value;
        },
        aggFunc: hierarchyAggregation,
        rowGroupIndex,
        hide: isColumnHidden || isColumnGrouping,
        cellRendererParams: {
          datasetUid,
          domain,
          wrapWithDivElement: true
        },
        cellRenderer: ValueRenderer,
        cellClassRules: {
          'total-row': (params) => params.node.isRowPinned(),
          'left-align': (params) => getCellClassRules(params, 'left'),
          'center-align': (params) => getCellClassRules(params, 'center'),
          'right-align': (params) => getCellClassRules(params, 'right'),
          'subtotal-row': (params) => params.node.level != -1 && params.node.footer
        },
        cellStyle: cellStyleFunction,
        cellClass: (params: CellClassParams) => {
          const fieldName = getFieldName(params) ?? '';
          // params.data contains the true/unformatted value
          // whereas params.value contains the formatted value from the renderer (exportHooks - processCellCallback).
          const dataValue = params.data?.[fieldName];
          const value = dataValue ?? params.value;
          const cellClassesParams = {
            colFieldName: column.fieldName,
            colDataTypeName: column.dataTypeName,
            node: params.node,
            context: params.context,
            value: value
          };
          return generateCellClasses(cellClassesParams);
        },
        headerClass: headerClassFunction,
        headerName: displayName,
        width: column.width
      } as ColDef;
    });
    return defs;
  };

  const exportAutoGroupAttributes = useAutoGroupAttributes({
    datasetUid,
    domain,
    isIndented: false,
    singleAutoColumnWidth: hierarchyConfig?.singleAutoColumnWidth,
    shouldGenerateCellClasses: true,
    vifOrderConfig: [],
    columnFormats: {}
  });

  const aggFuncs = useMemo<{
    [key: string]: IAggFunc;
  }>(() => {
    return {
      max: exportMaxFunc,
      min: exportMinFunc,
      count: exportCountFunc
    };
  }, []);

  /**
   * Conditionally render a ClientSide AgGrid implementation for XLSX exports if and only if
   * the user has selected the XLSX option in the Export Modal component and clicked on the
   * `Download` button.
   *
   * We have to render another table because AgGrid only exports data that is loaded on the
   * web client. This is not possible with a ServerSide implementation of AgGrid, so we have
   * to render the ClientSide table in the method below that has most of the same attributes
   * as our ServerSide table.
   *
   * see https://socrata.atlassian.net/wiki/spaces/PD/pages/2911895579/EN-65533+Excel+CSV+Export+with+formatting+for+AG+Grid
   */
  return FeatureFlags.value('enable_table_formatted_exports') && isExportTableRendered ? (
    <div className={`table-export-${vizUid}`} style={{ display: 'none' }}>
      <AgGridReact
        {...agGridReactAttributes}
        columnDefs={exportColDefs()}
        ref={gridRef}
        rowData={rowsToBeExported}
        onRowDataUpdated={activeHierarchyId == hierarchyConfig?.id ? handleExport : undefined}
        rowModelType="clientSide"
        groupDefaultExpanded={-1}
        modules={clientSideModules}
        onGridReady={(params) => {
          params.api.addAggFuncs({ max: exportMaxFunc, min: exportMinFunc, count: exportCountFunc });
        }}
        getRowId={undefined}
        isServerSideGroupOpenByDefault={undefined}
        serverSideDatasource={undefined}
        excelStyles={currentExcelStyles}
        groupDisplayType={'multipleColumns'}
        autoGroupColumnDef={exportAutoGroupAttributes}
        aggFuncs={aggFuncs}
      />
    </div>
  ) : null;
};

export default ExportGrid;
