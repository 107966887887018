import React from 'react';
import { Option } from 'ts-option';
import { ForgeIcon, ForgeInlineMessage } from '@tylertech/forge-react';
import { QueryMetaSuccess } from 'common/explore_grid/redux/store';
import { fetchTranslation } from 'common/locale';
import { ExportFormat } from 'common/downloadLinks';
import { shouldRenderWarning, shouldRenderLargeDatasetWarning } from './ExportHelper';

export interface DownloadWarningProps {
  rowCountFiltered?: number;
  rowCountAll?: number;
  selectedFiltered: boolean;
  isFileDownloadSelected: boolean;
  isVisualization: boolean;
  selectedResourceValue: ExportFormat | undefined;
  meta?: Option<QueryMetaSuccess>;
}

export const LARGE_DATASET_THRESHOLD = 999999;

export const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal') as string;

// This helper component handles displaying a warning message when "Download file" option is selected or implied.
// Currently, it handles two types of warning.
// 1. Large download of 1,000,000 rows and above.
//    a. On a dataset landing page, the warning text is slightly different.
// 2. Formatted XLSX export of 5,000 rows and above. This is available from a story only.

// ExportModal is used in several places, and has a different method for determining where this is included.
// - Dataset landing page
// - Exploration canvas
// - Story visualizations (including tables)
// - SOON TO BE ADDED Visualization Canvas

const DownloadWarning: React.FC<DownloadWarningProps> = ({
  rowCountFiltered,
  rowCountAll,
  selectedFiltered,
  isFileDownloadSelected,
  isVisualization,
  selectedResourceValue,
  meta
}) => {
  // Only display this warning for file download option
  if (!isFileDownloadSelected) {
    return null;
  }

  let warningTitle: string | undefined;
  let warningBody: string | undefined;

  const displayPartialExportWarning = shouldRenderWarning(
    selectedResourceValue,
    selectedFiltered,
    rowCountFiltered,
    rowCountAll
  );

  const displayLargeDatasetWarning = shouldRenderLargeDatasetWarning(
    selectedFiltered,
    rowCountFiltered,
    // if "meta" was passed, use the rowCount from it, otherwise we use the rowCountAll
    meta ? meta.map((m) => m.rowCount).getOrElseValue(0) : rowCountAll,
    LARGE_DATASET_THRESHOLD
  );

  // We need to determine whether ExportModal was included from a dataset landing page since it has a unique message.
  // There is no clear indication, other than to say that it wasn't from a viz or EC.
  // "isVisualization" checks for existence of vizUid param.
  // "meta" is only passed as a connected component prop from the EC.
  const isDLP = !isVisualization && !meta;

  // Set title and body
  if (displayPartialExportWarning) {
    // Partial export warning takes precedence
    warningTitle = t('partial_export_title');
    warningBody = t('partial_export_body');
  } else if (displayLargeDatasetWarning) {
    warningTitle = t('large_dataset_warning_title');
    // Dataset landing page has a different body.
    warningBody = isDLP ? t('large_dataset_warning_body') : t('large_dataset_warning_body_viz');
  }

  if (warningTitle && warningBody) {
    return (
      <ForgeInlineMessage theme="warning" className="partial-export-message">
        <ForgeIcon name="warning" slot="icon" />
        <div className="message-title" slot="title">
          {warningTitle}
        </div>
        <div>{warningBody}</div>
      </ForgeInlineMessage>
    );
  }

  return null;
};

export default DownloadWarning;
