import React from 'react';
import { isEmpty, get } from 'lodash';
import { useProcessDiscoveryResults } from 'common/visualizations/views/agGridReact/customHooks/useProcessDiscoveryResults';
import { ForgeList, ForgeListItem } from '@tylertech/forge-react';
import {
  ApplicationDescriptor,
  ContextParams
} from 'common/visualizations/views/agGridReact/helpers/processDiscoveryHelper';

interface ContentProps {
  primaryKeyValue: string | number;
  contextParams: ContextParams;
  newTableActions: string[];
}

export interface ProcessDescriptor {
  id: string;
  name: string;
}

export interface ProcessDiscoveryResults {
  applicationDescriptors?: ApplicationDescriptor[];
  processDescriptors?: ProcessDescriptor[];
}

const LinksList: React.FC<{ descriptors: any[]; newTableActions: string[] }> = ({
  descriptors,
  newTableActions
}) => {
  if (isEmpty(descriptors)) {
    return null;
  }
  const filteredDescriptors = isEmpty(newTableActions)
    ? descriptors
    : descriptors.filter((descriptor) => newTableActions.includes(descriptor.id));

  return (
    <>
      {filteredDescriptors.map((entry, index) => (
        <ForgeListItem key={index}>
          <a href={entry.url} target="_blank" rel="noopener noreferrer">
            {entry.name}
          </a>
        </ForgeListItem>
      ))}
    </>
  );
};

const ProcessDiscoveryContent: React.FC<ContentProps> = ({
  primaryKeyValue,
  contextParams,
  newTableActions
}) => {
  const {
    loading,
    processDiscoveryResults
  }: { loading: boolean; processDiscoveryResults: ProcessDiscoveryResults } = useProcessDiscoveryResults(
    primaryKeyValue,
    contextParams
  );

  const { applicationDescriptors = [] } = get(processDiscoveryResults, '[0]', {});
  if (loading) {
    return <div className="spinner-default" data-testid="process-discovery-spinner"></div>;
  }

  if (isEmpty(processDiscoveryResults)) {
    return <div data-testid="process-discovery-no-result">No results found</div>;
  }

  return (
    <div className="links">
      <ForgeList>
        <LinksList descriptors={applicationDescriptors} newTableActions={newTableActions} />
      </ForgeList>
    </div>
  );
};

export default ProcessDiscoveryContent;
