import $ from 'jquery';
import React, { useCallback } from 'react';
import classnames from 'classnames';

import Button, { VARIANTS, SIZES } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { ForgeIcon } from '@tylertech/forge-react';

/** Props for an individual view tab. */

const scope = 'shared.visualizations.charts.common.view_tabs';

/**
 * One tab of the chart/table tab selector on the bottom left of each visualization. Clicking on
 * it will switch to the corresponding tab.
 */
function ViewTab({
  onClick,
  selected,
  tabName,
  visualizationId,
  visualizationType
}: {
  onClick: () => void;
  selected: boolean;
  tabName: 'visualization' | 'summary-table';
  visualizationId: string;
  visualizationType: 'map' | 'chart' | 'table';
}) {
  // If forge is turned on, we want to use the new chart icon for charts.
  // If the forge icon is off, we use all the old socrata icons.
  const tabIcon =
    visualizationType === 'chart' ? (
      <ForgeIcon name="bar_chart" aria-hidden={true} tab-index={-1} data-testid="bar-chart-icon" />
    ) : (
      <SocrataIcon name={visualizationType as IconName} aria-hidden={true} tab-index={-1} />
    );

  const showFlyout = useCallback(
    ({ target }: { target: EventTarget }) => {
      target.dispatchEvent(
        new CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
          detail: {
            element: target,
            content: $('<div>', { class: 'socrata-flyout-title' }).text(I18n.t(visualizationType, { scope })),
            rightSideHint: false,
            belowTarget: false,
            dark: true
          },
          bubbles: true
        })
      );
    },
    [visualizationType]
  );

  const hideFlyout = ({ target }: { target: EventTarget }) => {
    target.dispatchEvent(
      new CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
        detail: null,
        bubbles: true
      })
    );
  };

  return (
    <Button
      aria-controls={`${tabName}-panel-${visualizationId}`}
      aria-label={I18n.t(`aria_label.${visualizationType}`, { scope })}
      aria-selected={selected}
      className={classnames(`btn-${tabName}-tab`, { selected })}
      data-testid={`${tabName}-button-${visualizationId}`}
      id={`${tabName}-button-${visualizationId}`}
      onClick={() => {
        onClick();
        $(`#${tabName}-panel-${visualizationId} label`).trigger('focus');
      }}
      onBlur={hideFlyout}
      onFocus={showFlyout}
      onMouseEnter={showFlyout}
      onMouseLeave={hideFlyout}
      role="tab"
      size={SIZES.X_SMALL}
      variant={VARIANTS.TRANSPARENT}
    >
      {/* We use slightly more descriptive text for screenreaders */}
      {tabIcon}
      <span className="btn-visualization-tab-label" aria-hidden={true} hidden={true}>
        {I18n.t(visualizationType, { scope })}
      </span>
    </Button>
  );
}

/**
 * Renders tabs for switching between the summary table and chart or map view of a visualization.
 * See ViewTabsContainer for the tab panel implementation.
 */
export function ViewTabs({
  isMap,
  onClickTableTab,
  onClickVisualizationTab,
  shouldShowTable,
  visualizationId
}: {
  isMap: boolean;
  onClickTableTab: () => void;
  onClickVisualizationTab: () => void;
  shouldShowTable: boolean;
  visualizationId: string;
}) {
  const visualizationType = isMap ? 'map' : 'chart';
  return (
    <>
      <ViewTab
        onClick={onClickVisualizationTab}
        selected={!shouldShowTable}
        tabName={'visualization'}
        visualizationId={visualizationId}
        visualizationType={visualizationType}
      />
      <ViewTab
        onClick={onClickTableTab}
        selected={shouldShowTable}
        tabName={'summary-table'}
        visualizationId={visualizationId}
        visualizationType="table"
      />
    </>
  );
}
