import { get, isNil, has } from 'lodash';

export const flagPath = 'socrata.featureFlags';

export const FeatureFlags = {
  // Returns true if feature flags are available, false otherwise. The flags may be unavailable
  // if we're not on a Socrata domain (i.e., in a Javascript-powered visualization embed).
  available: () => has(window, flagPath),
  value: function (key?: string) {
    if (typeof globalDefault() === 'boolean') {
      return this.valueOrDefault(key, globalDefault());
    }

    const flagValue = get(window, `${flagPath}.${key}`);

    if (isNil(flagValue)) {
      throw new Error(
        `Invalid feature flag: '${key}'. Check that window.${flagPath} is present or that withTestFeatureFlags() has set this feature flag.`
      );
    }

    return flagValue;
  },
  valueOrDefault: (key: string, defaultValue?: boolean) => {
    const flagValue = get(window, `${flagPath}.${key}`);
    if (isNil(flagValue)) {
      return defaultValue;
    }
    return flagValue;
  }
};

const globalDefault = () => get(window, 'socrata.featureFlags.DEFAULT_VALUE');
