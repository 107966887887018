import { ForgeRadio } from '@tylertech/forge-react';
import React, { FormEvent, FunctionComponent } from 'react';
import { fetchTranslation } from 'common/locale';
import { isEmpty } from 'lodash';

export interface FilterRadioButtonProps {
  selectedFiltered: boolean;
  handleRadioFilterChange: (selectedFiltered: boolean) => void;
  showFilteredDataOption: boolean;
  rowCountFiltered?: number;
  rowCountAll?: number;
  odata?: boolean;
}

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');
const translationWithOption = (k: string, option: any) =>
  fetchTranslation(k, 'shared.explore_grid.export_dataset_modal', undefined, option);

const FilterRadioButtons: FunctionComponent<FilterRadioButtonProps> = ({
  selectedFiltered,
  rowCountFiltered,
  rowCountAll,
  odata = false,
  showFilteredDataOption,
  handleRadioFilterChange
}) => {
  const onHandleRadioFilterChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    const isSelectedFiltered = target.value !== 'all';
    handleRadioFilterChange(isSelectedFiltered);
  };

  return (
    <div>
      <div
        className="filtered-radio-group"
        data-testid="radio-group-filtering"
        role="radiogroup"
        aria-label={t('dialog_radio')}
        onChange={onHandleRadioFilterChange}
      >
        {showFilteredDataOption && !odata && (
          <ForgeRadio className="filtered-data">
            <input
              type="radio"
              id="radio-filtered"
              name="radio-filters"
              data-testid="radio-option-filtered"
              value="filtered"
              defaultChecked={selectedFiltered}
            />
            <label htmlFor="radio-filtered">
              {isEmpty(window.STORY_DATA)
                ? translationWithOption('modified_data', { rowCount: rowCountFiltered })
                : translationWithOption('filtered_data', { rowCount: rowCountFiltered })}
            </label>
          </ForgeRadio>
        )}
        <ForgeRadio className="all-data">
          <input
            type="radio"
            id="radio-all"
            name="radio-filters"
            data-testid="radio-option-all"
            value="all"
            defaultChecked={!selectedFiltered}
          />
          <label htmlFor="radio-all">{translationWithOption('all_data', { rowCount: rowCountAll })}</label>
        </ForgeRadio>
      </div>
    </div>
  );
};

export default FilterRadioButtons;
