import I18n from 'common/i18n';
import { ViewColumn } from 'common/types/viewColumn';
import { useCallback } from 'react';
import { ExportData } from '../types';
import { AgGridReact } from '@ag-grid-community/react';
import moment from 'moment';
import { hideToastById } from 'common/components/ToastNotification/Toastmaster';
import { OrderConfig } from 'common/visualizations/vif';
import { updateColumnsSort } from '../helpers/SortHelpers';
import {
  IRowNode,
  ProcessCellForExportParams,
  ProcessRowGroupForExportParams
} from '@ag-grid-community/core';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { GROUP_COLUMN_PREFIX, NULL_GROUPING } from '../Constants';
import { join, unescape } from 'lodash';
import { SoQLType } from 'common/types/soql';
import { unsupportedDateFormats } from '../helpers/TableExportFormatting';

export interface IInitializeExportCallback {
  selectedFiltered: boolean;
}

interface IUseInitializeExport {
  viewColumns: ViewColumn[];
  getExportData: ((selectedFiltered: boolean) => Promise<ExportData>) | undefined;
  setIsExportTableRendered: React.Dispatch<React.SetStateAction<boolean>>;
  setRowsToBeExported: React.Dispatch<React.SetStateAction<unknown[] | undefined>>;
}

/**
 *
 * @param viewColumns Visible columns in the rendered AgGrid table
 * @param getExportData A method that fetches the data that is needed to be exported
 * @param setIsExportTableRendered A `setState` method that handles the condition for determining if the export table has rendered
 * @param setRowsToBeExported A `setState` method that handles the rows of the rendered export table
 * @returns The method to initialize the export table
 */
export const useInitializeExport = ({
  viewColumns,
  getExportData,
  setIsExportTableRendered,
  setRowsToBeExported
}: IUseInitializeExport) => {
  return useCallback(
    (data: IInitializeExportCallback) => {
      if (viewColumns.length > 0 && getExportData) {
        getExportData(data.selectedFiltered)?.then((exportData) => {
          setIsExportTableRendered(true);
          setRowsToBeExported(exportData.rows);
        });
      }
    },
    [viewColumns, getExportData, setIsExportTableRendered, setRowsToBeExported]
  );
};

const skipSubtotalHeader = (
  node: IRowNode,
  context: CustomAgGridContext,
  field: string | boolean | undefined
) => {
  if (typeof field != 'string') return false;

  const { groupData, footer, level } = node;
  const { groupedColumns, showSubTotal } = context;

  return (
    groupData && !footer && level < groupedColumns.length && !groupedColumns.includes(field) && showSubTotal
  );
};

interface IUseHandleExport {
  gridRef: React.RefObject<AgGridReact<any>>;
  isExportTableRendered: boolean;
  rowsToBeExported: unknown[] | undefined;
  datasetName: string | undefined;
  setIsExportTableRendered: React.Dispatch<React.SetStateAction<boolean>>;
  setRowsToBeExported: React.Dispatch<React.SetStateAction<unknown[] | undefined>>;
  vifOrderConfig: OrderConfig[];
}

/**
 *
 * @param gridRef The reference object to the rendered export table
 * @param datasetName The name of the dataset to be set as part of the filename of the exported XLSX file
 * @param isExportTableRendered The condition for determining if the export table has rendered
 * @param setIsExportTableRendered A `setState` method that handles the condition for determining if the export table has rendered
 * @param rowsToBeExported The rows of the rendered export table
 * @param setRowsToBeExported A `setState` method that handles the rows of the rendered export table
 * @param vifOrderConfig Contains the sorting configuration of the table
 * @returns The actual method that exports the rendered table
 */
export const useHandleExport = ({
  gridRef,
  datasetName,
  isExportTableRendered,
  rowsToBeExported,
  setIsExportTableRendered,
  setRowsToBeExported,
  vifOrderConfig
}: IUseHandleExport) => {
  updateColumnsSort({ api: gridRef.current?.api, vifOrderConfig, isIndented: undefined });
  return useCallback(() => {
    if (isExportTableRendered && gridRef.current && rowsToBeExported !== undefined) {
      const currentDate = moment().format('YYYYMMDD');
      const fileName = `${datasetName}_${currentDate}`;
      gridRef.current.api.exportDataAsExcel({
        fileName: fileName,
        processCellCallback(params: ProcessCellForExportParams) {
          return processCellCallback(params);
        },
        processRowGroupCallback(params) {
          const { node } = params;
          const noValueText = I18n.t('shared.visualizations.charts.common.summary_table.no_value');
          const value = node.key === NULL_GROUPING ? noValueText : node.key;
          // Grouping value
          if (!node.footer || isExportSubtotalCell(params)) {
            return value;
          }

          return '';
        }
      });
      hideToastById('export-modal-toast');
      setIsExportTableRendered(false);
      setRowsToBeExported(undefined);
    }
  }, [
    datasetName,
    gridRef,
    isExportTableRendered,
    rowsToBeExported,
    setIsExportTableRendered,
    setRowsToBeExported
  ]);
};

export const processCellCallback = (params: ProcessCellForExportParams) => {
  const colDef = params.column.getColDef();
  // showRowGroup contains fieldName when grouped
  const field = colDef.field ?? colDef.showRowGroup;
  if (isCellExportEmpty(params, field)) {
    return '';
  }
  const renderer = exportRenderer(params);
  return exportReturnValues(renderer, params, field);
};

const getUrlValue = (params: ProcessCellForExportParams<any, any>, field: string | undefined | boolean) => {
  if (!params.value && params.node?.field === field) {
    return I18n.t('shared.visualizations.charts.common.no_value');
  }
  return params.value;
};

const isCellExportEmpty = (params: ProcessCellForExportParams, field: string | boolean | undefined) => {
  const isValueUndefined = params.value === undefined;
  const isNullGrouping = params.node?.key === NULL_GROUPING && isValueUndefined;
  return (
    !params.node ||
    skipSubtotalHeader(params.node, params.context, field) ||
    isNullGrouping ||
    isValueUndefined
  );
};

// If colDef.cellRenderer is a string, it means that the column is a group, and we should use the grouping renderer, which is:
// common/visualizations/views/agGridReact/customHooks/useAutoGroupAttributes.ts:L161
// Reference:
// https://www.ag-grid.com/javascript-data-grid/group-cell-renderer/#configuration
const exportRenderer = (params: ProcessCellForExportParams) => {
  const colDef = params.column.getColDef();
  const rendererParams = {
    value: params.value,
    context: params.context,
    colDef: colDef,
    data: params.node?.data,
    node: params.node,
    forExport: true
  };
  return typeof colDef.cellRenderer === 'string'
    ? colDef.cellRendererParams.innerRenderer(rendererParams)
    : colDef.cellRenderer(rendererParams);
};

const isExportSubtotalCell = (
  params: ProcessCellForExportParams<any, any> | ProcessRowGroupForExportParams<any, any>
) => {
  const isRootLevel = params.node?.level === -1;
  return !isRootLevel && params.node?.footer;
};

const isExportTotalCell = (
  params: ProcessCellForExportParams<any, any> | ProcessRowGroupForExportParams<any, any>
) => {
  return params.node?.isRowPinned();
};

const exportReturnValues = (
  renderer: { props: { children: any } },
  params: ProcessCellForExportParams,
  field: boolean | string | undefined
) => {
  let formattedValue = typeof renderer === 'string' ? renderer : renderer.props.children;
  // params.value for a non-subtotal cell is the unformatted value,
  // whereas params.value for subtotals is the value being passed from processRowGroupCallback() (including the subtotal label)
  if (isURL(params, field)) {
    formattedValue = getUrlValue(params, field);
  }
  // we expect to display the email value as text in Excel, instead of the formatted value with anchor tags.
  if (isEmail(params)) {
    formattedValue = params.value;
  }

  if (isExportSubtotalCell(params) || isExportTotalCell(params)) {
    // If field and params.node.field are equal, this is a subtotal hierarchy cell and should be appended with the subtotal label.
    // Otherwise, the cell contains an aggregation value.
    const withSubtotalLabel = field === params.node?.field;
    const subtotalReturnValue = withSubtotalLabel
      ? `${I18n.t('shared.visualizations.charts.table.subtotal')} ${formattedValue}`
      : formattedValue;

    // If the subtotal/total cell contains purely just date value (does not include cells with 'Subtotal <date-value>'),
    // return the value right away, no need to format it
    const isDateCellForTotalOrSubtotal =
      !withSubtotalLabel && isDate(params, field) && moment(params.value, moment.ISO_8601, true).isValid();
    if (isDateCellForTotalOrSubtotal) {
      return params.value;
    }

    // There are instances where formattedValue is an array, e.g., ['Subtotal', '', '$100'].
    // If formattedValue is a string and represents a subtotal, this is most likely a column aggregation value,
    // and we also want to display the formattedValue.
    return typeof formattedValue == 'string' ? subtotalReturnValue : join(formattedValue, '');
  }

  if (isDate(params, field)) {
    const dateViewFormat = params.context.columnFormats[field as string]?.format.view as string;
    if (!unsupportedDateFormats.includes(dateViewFormat)) {
      formattedValue = params.value;
    }
  }

  const dataTypeName = getDatatypeName(params.context.columnMetadata, field);
  if (dataTypeName === 'text') {
    return unescape(formattedValue);
  }
  return formattedValue;
};

const isEmail = (params: ProcessCellForExportParams) => {
  const colDef = params.column.getColDef();
  let fieldName = colDef.field ?? colDef.colId;
  fieldName = fieldName?.replace(`${GROUP_COLUMN_PREFIX}-`, '');
  return (
    params.context.columnMetadata.find((column: ViewColumn) => column.fieldName === fieldName)?.format
      ?.displayStyle === 'email'
  );
};

const isURL = (params: ProcessCellForExportParams, field: boolean | string | undefined) => {
  const dataTypeName = getDatatypeName(params.context.columnMetadata, field);
  return dataTypeName == 'url';
};

const isDate = (params: ProcessCellForExportParams, field: boolean | string | undefined) => {
  const dataTypeName = getDatatypeName(params.context.columnMetadata, field);
  return dataTypeName == SoQLType.SoQLFloatingTimestampT;
};

const getDatatypeName = (columnMetadata: ViewColumn[], field: boolean | string | undefined) => {
  return columnMetadata.find((column: any) => column.fieldName == field)?.dataTypeName;
};
