/**
 * Register all used Forge Icons in this file in order to get icons to render
 * throughout the platform.
 *
 * Importing the desired icon(s) and defining them
 * within the Forge icon registry should be done as early in your application
 * bootstrapping process as possible. Make sure all icons are defined, prior
 * to the page rendering or else the component will not be able to find the
 * icon data when it is instantiated.
 */
import {
  tylIconAccountBalance,
  tylIconAccountCircle,
  tylIconAdd,
  tylIconAddCircle,
  tylIconAddCircleOutline,
  tylIconArrowBack,
  tylIconEdit,
  tylIconArrowUpward,
  tylIconAttachFile,
  tylIconBarChart,
  tylIconBusiness,
  tylIconCampaign,
  tylIconChevronLeft,
  tylIconChevronRight,
  tylIconCheck,
  tylIconCheckCircleOutline,
  tylIconCheckCircle,
  tylIconClear,
  tylIconClose,
  tylIconCode,
  tylIconContentCopy,
  tylIconContentPaste,
  tylIconDangerous,
  tylIconDelete,
  tylIconDomain,
  tylIconDragHandle,
  tylIconDragIndicator,
  tylIconError,
  tylIconFace,
  tylIconFilterList,
  tylIconFormatPaint,
  tylIconFunctions,
  tylIconGroups,
  tylIconHelpOutline,
  tylIconHistory,
  tylIconHowToReg,
  tylIconInfo,
  tylIconInfoOutline,
  tylIconInsertPhoto,
  tylIconKeyboardArrowRight,
  tylIconKeyboardArrowUp,
  tylIconLock,
  tylIconLogout,
  tylIconRefresh,
  tylIconMenuBook,
  tylIconMoreVert,
  tylIconNotifications,
  tylIconOndemandVideo,
  tylIconOpenInBrowser,
  tylIconPeople,
  tylIconPerson,
  tylIconPrint,
  tylIconPlayArrow,
  tylIconPublic,
  tylIconRedo,
  tylIconRemoveCircle,
  tylIconRemoveCircleOutline,
  tylIconSchool,
  tylIconSearch,
  tylIconSettings,
  tylIconSwapHoriz,
  tylIconTranslate,
  tylIconUndo,
  tylIconVideoLibrary,
  tylIconViewColumn,
  tylIconTextFields,
  tylIconWarning,
  tylIconOpenInNew,
  tylIconLayers,
  tylIconMenu,
  tylIconFormatAlignCenter,
  tylIconFormatAlignLeft,
  tylIconFormatAlignRight,
  tylIconFilterAlt,
  tylIconListAlt,
  tylIconFilterListAlt,
  tylIconArrowDownward,
  tylIconKeyboardArrowDown,
  tylIconAddAlert,
  tylIconNotificationsActive,
  tylIconNotificationsOff,
  tylIconMailOutline,
  tylIconApi,
  tylIconVolumeMute,
  tylIconSwapVert
} from '@tylertech/tyler-icons/standard';
import {
  tylIconAccountGroup,
  tylIconAccountCheck,
  tylIconAlertCircleOutline,
  tylIconArrangeBringForward,
  tylIconCalendar,
  tylIconCalendarClock,
  tylIconCardPlus,
  tylIconChartBox,
  tylIconCheckboxMarkedOutline,
  tylIconCheckboxMarkedCircleOutline,
  tylIconCheckDecagram,
  tylIconCircleEditOutline,
  tylIconCloseCircle,
  tylIconCursorMove,
  tylIconDatabaseEdit,
  tylIconDatabasePlus,
  tylIconDockBottom,
  tylIconDotsVertical,
  tylIconFileMultipleOutline,
  tylIconFileTree,
  tylIconFilter,
  tylIconFilterPlus,
  tylIconFilterVariantRemove,
  tylIconFormatText,
  tylIconGroup,
  tylIconHelpCircleOutline,
  tylIconLockAlert,
  tylIconLockOpen,
  tylIconPound,
  tylIconSetNone,
  tylIconSortAlphabeticalAscending,
  tylIconSortAlphabeticalDescending,
  tylIconSortAlphabeticalDescendingVariant,
  tylIconUpdate,
  tylIconVariable,
  tylIconViewColumnOutline,
  tylIconMapMarker,
  tylIconMapMarkerOutline,
  tylIconLinkVariant,
  tylIconChevronDoubleDown,
  tylIconChevronDoubleUp,
  tylIconDatabase,
  tylIconTooltipEdit,
  tylIconFilterMenu,
  tylIconFilterVariant,
  tylIconSort,
  tylIconTrashCan,
  tylIconCog,
  tylIconEyeOff,
  tylIconDatabaseArrowRight,
  tylIconTransitConnectionHorizontal,
  tylIconChartTimelineVariant,
  tylIconChartTimelineVariantShimmer,
  tylIconHeadQuestionOutline,
  tylIconOfficeBuilding,
  tylIconMap,
  tylIconNewspaperVariantOutline,
  tylIconDatabaseCog,
  tylIconFormatBold,
  tylIconFormatItalic,
  tylIconSortAscending,
  tylIconSortDescending,
  tylIconCompareHorizontal,
  tylIconPlaylistEdit,
  tylIconClockOutline,
  tylIconFileTableBox,
  tylIconShareVariant,
  tylIconVolumeHigh,
  tylIconFileDocument,
  tylIconPercent,
  tylIconFunction,
  tylIconSetAll,
  tylIconSetCenter,
  tylIconSetCenterRight,
  tylIconSetLeftCenter,
  tylIconTrashCanOutline,
  tylIconWizardHat,
  tylIconDragHorizontal,
  tylIconAlertDecagram,
  tylIconSparkles,
  tylIconAlert
} from '@tylertech/tyler-icons/extended';
import {
  tylIconDownload,
  tylIconFilterVariantCheck,
  tylIconTylerTalkingTLogo,
  tylIconDatabaseFilter
} from '@tylertech/tyler-icons/custom';
import { IconRegistry } from '@tylertech/forge';

IconRegistry.define([
  tylIconAccountBalance,
  tylIconAccountCheck,
  tylIconAccountCircle,
  tylIconAccountGroup,
  tylIconAdd,
  tylIconAddCircle,
  tylIconAddCircleOutline,
  tylIconAlertCircleOutline,
  tylIconArrowBack,
  tylIconArrangeBringForward,
  tylIconEdit,
  tylIconArrowUpward,
  tylIconAttachFile,
  tylIconBarChart,
  tylIconBusiness,
  tylIconCalendar,
  tylIconCalendarClock,
  tylIconCardPlus,
  tylIconCampaign,
  tylIconChartBox,
  tylIconCheck,
  tylIconCheckboxMarkedCircleOutline,
  tylIconCheckboxMarkedOutline,
  tylIconCheckCircleOutline,
  tylIconCheckCircle,
  tylIconCheckDecagram,
  tylIconChevronLeft,
  tylIconChevronRight,
  tylIconCircleEditOutline,
  tylIconClear,
  tylIconClose,
  tylIconCloseCircle,
  tylIconCode,
  tylIconContentCopy,
  tylIconContentPaste,
  tylIconCursorMove,
  tylIconDatabase,
  tylIconDatabaseEdit,
  tylIconDatabasePlus,
  tylIconDockBottom,
  tylIconDomain,
  tylIconDownload,
  tylIconDotsVertical,
  tylIconDangerous,
  tylIconDelete,
  tylIconDragHandle,
  tylIconDragIndicator,
  tylIconError,
  tylIconFace,
  tylIconFileMultipleOutline,
  tylIconFileTree,
  tylIconFilter,
  tylIconFilterList,
  tylIconFilterPlus,
  tylIconFilterVariant,
  tylIconFilterVariantCheck,
  tylIconFilterVariantRemove,
  tylIconFormatPaint,
  tylIconFormatText,
  tylIconFunctions,
  tylIconRefresh,
  tylIconGroup,
  tylIconGroups,
  tylIconHelpCircleOutline,
  tylIconHelpOutline,
  tylIconHistory,
  tylIconHowToReg,
  tylIconInfo,
  tylIconInfoOutline,
  tylIconInsertPhoto,
  tylIconKeyboardArrowRight,
  tylIconKeyboardArrowUp,
  tylIconLayers,
  tylIconLinkVariant,
  tylIconChevronDoubleDown,
  tylIconChevronDoubleUp,
  tylIconListAlt,
  tylIconLock,
  tylIconLockAlert,
  tylIconLockOpen,
  tylIconLogout,
  tylIconMapMarkerOutline,
  tylIconMenuBook,
  tylIconMoreVert,
  tylIconNotifications,
  tylIconOndemandVideo,
  tylIconOpenInBrowser,
  tylIconPeople,
  tylIconPerson,
  tylIconPlayArrow,
  tylIconPound,
  tylIconPrint,
  tylIconPublic,
  tylIconRedo,
  tylIconRemoveCircle,
  tylIconRemoveCircleOutline,
  tylIconSchool,
  tylIconSearch,
  tylIconSetNone,
  tylIconSettings,
  tylIconSort,
  tylIconSortAlphabeticalAscending,
  tylIconSortAlphabeticalDescending,
  tylIconSortAlphabeticalDescendingVariant,
  tylIconSwapHoriz,
  tylIconTooltipEdit,
  tylIconTranslate,
  tylIconTrashCanOutline,
  tylIconTylerTalkingTLogo,
  tylIconUndo,
  tylIconUpdate,
  tylIconVariable,
  tylIconViewColumn,
  tylIconViewColumnOutline,
  tylIconVideoLibrary,
  tylIconWarning,
  tylIconWizardHat,
  tylIconTextFields,
  tylIconMapMarker,
  tylIconOpenInNew,
  tylIconFilterMenu,
  tylIconMenu,
  tylIconFormatAlignCenter,
  tylIconFormatAlignLeft,
  tylIconFormatAlignRight,
  tylIconTrashCan,
  tylIconCog,
  tylIconEyeOff,
  tylIconDatabaseCog,
  tylIconTransitConnectionHorizontal,
  tylIconFilterAlt,
  tylIconChartTimelineVariant,
  tylIconChartTimelineVariantShimmer,
  tylIconHeadQuestionOutline,
  tylIconOfficeBuilding,
  tylIconDatabaseArrowRight,
  tylIconMap,
  tylIconNewspaperVariantOutline,
  tylIconDatabaseFilter,
  tylIconFormatBold,
  tylIconFormatItalic,
  tylIconSortAscending,
  tylIconSortDescending,
  tylIconFilterListAlt,
  tylIconArrowDownward,
  tylIconKeyboardArrowDown,
  tylIconCompareHorizontal,
  tylIconPlaylistEdit,
  tylIconAddAlert,
  tylIconNotificationsActive,
  tylIconNotificationsOff,
  tylIconMailOutline,
  tylIconApi,
  tylIconFileTableBox,
  tylIconClockOutline,
  tylIconVolumeMute,
  tylIconVolumeHigh,
  tylIconShareVariant,
  tylIconFileDocument,
  tylIconPercent,
  tylIconFunction,
  tylIconSetLeftCenter,
  tylIconSetCenterRight,
  tylIconSetAll,
  tylIconSetCenter,
  tylIconDragHorizontal,
  tylIconAlertDecagram,
  tylIconSparkles,
  tylIconAlert,
  tylIconSwapVert
]);
