import { compact, filter, get, includes, isEqual, last, merge, set, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { AgGridReact, AgGridReactProps, AgReactUiProps } from '@ag-grid-community/react';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import uuid from 'uuid';
import DOMPurify from 'dompurify';

import { TableColumnFormat, FormatStyle } from 'common/authoring_workflow/reducers/types';
import { PdfPreviewModal } from 'common/components/PdfPreviewModal';
import { ReportMetadata } from 'common/components/PdfPreviewModal/types';
import { TIME_FORMATS } from 'common/DataTypeFormatter';
import { Hierarchy, HierarchyColumnConfig, LinkedAction } from 'common/visualizations/vif';
import { FeatureFlags } from 'common/feature_flags';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  FirstDataRenderedEvent,
  SortChangedEvent,
  ColumnVisibleEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  Column,
  RowClassParams,
  MenuItemDef,
  GetMainMenuItemsParams,
  ModelUpdatedEvent,
  ICellRendererParams,
  ProcessCellForExportParams,
  SideBarDef
} from '@ag-grid-community/core';
import { Filters } from 'common/components/FilterBar/types';
import { ViewColumn } from 'common/types/viewColumn';
import { OrderConfig } from 'common/visualizations/vif';
import { agGridDataFormatter, getAgTableRowStyle } from './helpers/TableColumnFormatting';

import ReactDOM from 'react-dom';
import { useGroupStateRestore } from './customHooks/useGroupStateRestore';
import useColDefs from './customHooks/useColDefs';
import SocrataTooltip from './SocrataTooltip';
import './index.scss';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { agGridFilterToVifFilter } from 'common/visualizations/helpers/AgGridHelpers';
import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import NoRowsAgGridOverlay from 'common/components/NoRowsAgGridOverlay';
import { isLargeDesktop } from 'common/visualizations/helpers/MediaQueryHelper';
import { updateColumnsSort } from './helpers/SortHelpers';
import { useAutoGroupAttributes } from './customHooks/useAutoGroupAttributes';
import { GROUP_COLUMN_PREFIX } from './Constants';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { useDeepCompareEffect } from 'common/visualizations/views/agGridReact/customHooks/useDeepCompareEffect';
import { ExportData, UniqueValuesData } from './types';
import { renderPrintTablePseudoComponent } from 'common/visualizations/helpers/AgGridPrintHelper';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import ExportGrid, { IExportGridParams } from './ExportGrid';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { AgColumnFilter } from 'common/types/agGrid/filters';
import { getCurrentUser } from 'common/current_user';
import { ContextParams } from './helpers/processDiscoveryHelper';

// It's ok for this license key to be exposed (https://www.ag-grid.com/javascript-data-grid/licensing/#setting-the-license-key)
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-073142}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tyler_Technologies}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Socrata}_only_for_{38}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Socrata}_need_to_be_licensed___{Socrata}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_July_2026}____[v3]_[01]_MTc4Mzk4MzYwMDAwMA==953dd63b704319b712726e8a48b3898f'
);

const defaultColDef = {
  sortable: true,
  resizable: true,
  minWidth: 80,
  maxWidth: 512,
  tooltipComponent: 'customTooltip',
  wrapHeaderText: false,
  autoHeaderHeight: false,
  wrapText: false,
  autoHeight: false
};

export interface AgGridProps {
  // columnMetadata: This is the same as vifColumns, but has all the columns in the dataset.
  // TODO: This is redundant. I dont know why we need this.
  columnMetadata: ViewColumn[];
  nonStandardAggregations: ColumnAggregation[] | null;
  datasource: IServerSideDatasource;
  getGrandTotalRow: (hierarchyConfig: Hierarchy) => Promise<any>;
  onColumnReorder: (columnState: ColumnState[]) => void;
  onColumnResize: (columns: Column[] | null) => void;
  onColumnRowGroupChange: (columnState: ColumnState[], columns: Column[], hierarchyId?: string) => void;
  onColumnSort: (columnState: ColumnState[], hierarchyId?: string) => void;
  onColumnValueChange: (columns: Column[], hierarchyId?: string) => void;
  onColumnVisibilityChange: (
    columnState: ColumnState[],
    columns: Column[] | null,
    hierarchyId?: string
  ) => void;
  onPrintModalClose: () => void;
  onFilterChange: (newFilter: Filters, agFilterModel?: AgColumnFilter) => void;
  datasetUid: string;
  datasetName?: string;
  description?: string;
  domain: string;
  columnFormats: { [key: string]: TableColumnFormat };
  hierarchyConfig?: Hierarchy;
  vifFilters: Filters;
  vifColumns: ViewColumn[];
  vifOrderConfig: OrderConfig[];
  vifParameterOverrides: ClientContextVariable[];
  agGridOpenNodeLevel: number;
  searchString?: string;
  paginationPageSize?: number;
  defaultColDefOverrides?: ColDef;
  useSetFilters?: boolean;
  agFilterModel?: AgColumnFilter;
  displayColumnFilters?: boolean;
  showAgGridColumnMenu?: boolean;
  showAgGridColumnAggregations?: boolean;
  isIndented?: boolean;
  isPrintModalOpen: boolean;
  initializeRowStripeStyle?: () => RowStripeStyle;
  printMode?: boolean;
  pagination?: boolean;
  openToolPanelByDefault?: boolean;
  title?: string;
  vizUid?: string;
  getExportData?: (selectedFiltered: boolean) => Promise<ExportData>;
  headerFormat: FormatStyle;
  activeHierarchyId: string | undefined;
  actionColumnDef?: ColDef;
  linkedAction: LinkedAction;
  processDiscoveryContext: ContextParams;
  newTableActions: string[];
  showColumnMenuKebab?: boolean;
  getUniqueValues?: (columns: ViewColumn[], order: OrderConfig[]) => Promise<UniqueValuesData>;
}

const Grid = (props: AgGridProps) => {
  const {
    agGridOpenNodeLevel,
    columnMetadata,
    columnFormats,
    datasetUid,
    datasetName,
    description,
    datasource,
    defaultColDefOverrides,
    displayColumnFilters,
    domain,
    getGrandTotalRow,
    initializeRowStripeStyle,
    isIndented,
    isPrintModalOpen,
    hierarchyConfig,
    nonStandardAggregations,
    onColumnReorder,
    onColumnResize,
    onColumnRowGroupChange,
    onColumnSort,
    onColumnValueChange,
    onColumnVisibilityChange,
    onFilterChange,
    onPrintModalClose,
    openToolPanelByDefault,
    paginationPageSize,
    searchString,
    showAgGridColumnMenu,
    title,
    agFilterModel,
    useSetFilters,
    vifColumns,
    vifFilters,
    vifOrderConfig,
    vifParameterOverrides,
    vizUid,
    getExportData,
    headerFormat,
    activeHierarchyId,
    actionColumnDef,
    linkedAction,
    processDiscoveryContext,
    newTableActions,
    showColumnMenuKebab,
    getUniqueValues
  } = props;
  const showSubTotal = hierarchyConfig?.showSubTotal ?? false;
  const flexibleHierarchiesEnabled = FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false);
  const showAgGridColumnAggregations =
    flexibleHierarchiesEnabled && (props.showAgGridColumnAggregations ?? true);

  const prevHierarchyConfig = useRef<Hierarchy>();
  const agGridContext = useRef<CustomAgGridContext>({} as CustomAgGridContext);
  const [filters, setFilters] = useState<Filters>([]);
  const [parameterOverrides, setParameterOverrides] = useState<ClientContextVariable[]>([]);
  const [columns, setColumns] = useState<ViewColumn[]>([]);
  const [vifColumnFormat, setVifColumnFormat] = useState<{ [key: string]: TableColumnFormat }>({});
  const [hierarchyColumns, setHierarchyColumns] = useState<HierarchyColumnConfig[]>([]);
  const [removedHierarchyColumnNames, setRemovedHierarchyColumnNames] = useState<Set<string>>(new Set());
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [grandTotalData, setGrandTotalData] = useState<any[]>([]);
  const { isServerSideGroupOpenByDefault, onRowGroupOpened } = useGroupStateRestore();
  const [currentRowStripeStyle, setCurrentRowStripeStyle] = useState<RowStripeStyle | undefined>({});
  const [currentSortState, setCurrentSortState] = useState<OrderConfig[] | []>([]);

  const shouldOpenToolPanel = !!(flexibleHierarchiesEnabled && openToolPanelByDefault);
  const isPivotFlagEnabled = FeatureFlags.valueOrDefault('enable_ag_pivot_mode', false);
  const sideBarConfig: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: !isPivotFlagEnabled, // enables the pivot toggle button at the top of the sidebar
          suppressColumnExpandAll: true,
          suppressColumnMove: false
        }
      }
    ],
    // Set this value to the `id` of a panel you want opened by default.
    // Comment this out, or set `hiddenByDefault: true` to hide the tool panel by default.
    defaultToolPanel: (shouldOpenToolPanel && 'columns') || undefined
  };

  function updateOverlayStatus(api: GridApi<any>): void {
    if (api.getDisplayedRowCount() === 0) {
      // If no rows were returned tell AgGrid to display the no rows overlay.
      // It can't do this automatically when using the server-side model.
      // There are instances in which the no rows overlay is not showing.
      // Here’s a solution from ag-Grid's GitHub issues.
      // https://github.com/ag-grid/ag-grid/issues/3849#issuecomment-1136706407
      setTimeout(() => {
        api?.showNoRowsOverlay();
      });
    } else {
      // Similarly, AgGrid can't hide the overlay itself if there is something to show.
      api.hideOverlay();
    }
  }

  function initiatePrintMode(): boolean {
    try {
      // Get the current URL from the window object
      const urlObj = new URL(window.location.href);

      // Extract query parameters
      const params = urlObj.searchParams;

      // Check if 'print' equals 'true' and 'itemId' exists
      const isPrint = params.get('print') === 'true';
      const hasItemId = params.has('itemId');

      // Return true only if both conditions are satisfied
      return isPrint && hasItemId;
    } catch (error) {
      return false; // Handle potential URL issues
    }
  }

  const refreshGridData = (purge?: boolean) => {
    if (gridApi) {
      gridApi.refreshServerSide({ purge });
    }
  };

  // useLayoutEffect runs synchronously after render which helps avoid some ag grid spinning / loading issues
  // don't put anything in here that is async, like data fetching
  useLayoutEffect(() => {
    let shouldRefreshGridData = false;
    let hardRefresh = false;

    if (
      hierarchyConfig &&
      hierarchyConfig.columnConfigurations &&
      !isEqual(hierarchyColumns, hierarchyConfig.columnConfigurations)
    ) {
      const removedColumnNames = hierarchyColumns
        .filter((h) => {
          const vifColumnConfig = hierarchyConfig?.columnConfigurations.find(
            (c) => c.columnName === h.columnName
          );
          const columnRemoved = !vifColumnConfig;
          const columnNoLongerGrouping = h.isGrouping && !vifColumnConfig?.isGrouping;
          return columnRemoved || columnNoLongerGrouping;
        })
        .map(({ columnName }) => columnName);
      setHierarchyColumns(hierarchyConfig.columnConfigurations);
      setRemovedHierarchyColumnNames(new Set(removedColumnNames));
    }

    // the grid itself doesn't use the vif filters at all (they are used during query construction in TableDataHelpers)
    // but we keep a copy of them in state so that we can detect when they change, and refresh the grid.
    if (!isEqual(filters, vifFilters)) {
      if (!fromAddFilter()) {
        shouldRefreshGridData = true;
        // the grid may need a full refresh if the previous filters returned 0 rows
        hardRefresh = true;
      }
      setFilters(vifFilters as Filters);
    }

    // Similar to tracking state of vif filters, we also have a copy of vif parameterOverrides
    // to detect changes and refresh the grid when any values change
    if (!isEqual(parameterOverrides, vifParameterOverrides)) {
      setParameterOverrides(vifParameterOverrides);
      shouldRefreshGridData = true;
      hardRefresh = true;
    }

    if (!isEqual(vifColumnFormat, columnFormats)) {
      setVifColumnFormat(columnFormats);
      // Removing focus from the currently focused cell helps avoid UI issues
      // such as conditional input fields losing focus with every keypress.
      gridApi?.clearFocusedCell();
    }

    if (!isEqual(columns, vifColumns)) {
      // if a new column has been added, tell ag-grid to refresh its data
      if (vifColumns.length > columns.length) {
        shouldRefreshGridData = true;
      }
      // if a column was deleted and a new one took its place, we should also refresh
      const vifColumnNames = vifColumns.map((col) => col.fieldName);
      const columnNames = columns.map((col) => col.fieldName);

      if (vifColumnNames.some((colName) => !columnNames.includes(colName))) {
        // we don't ask ag-grid to refresh in all cases, since closing the open groups can be a disruptive experience
        // and isn't necessary if a column is removed
        shouldRefreshGridData = true;
      }

      setColumns(vifColumns);
    }

    // Update the width of grouped columns for non-indented layout
    if (gridApi) {
      updateGroupedColumnsWidth(gridApi);
    }

    // if a grouped column was sorted on the AX (which is a different grid instance than the story version),
    // the map of vif columns to agColumn defs below will not pick that sort up, because the grouped columns are
    // auto-created by AG Grid and therefore the mapping function never touches them.
    // here we manually compare the vif's view of grouped column sorts with ag-grid's view of grouped column sorts,
    // and if they don't match, we update ag-grid's column state
    if (gridApi && hierarchyConfig?.order) {
      const agColumnState = gridApi.getColumnState();
      const agGroupedColumnSortState = agColumnState
        .filter((col) => col.colId && col.sort && col.colId.startsWith(GROUP_COLUMN_PREFIX))
        .map(({ colId, sort, sortIndex }) => ({ colId, sort, sortIndex }));

      const vifGroupedColumnSortState = hierarchyConfig.order
        .map((order, orderIndex) => ({
          sort: order.ascending ? 'asc' : 'desc',
          sortIndex: orderIndex,
          colId: order.columnName
        }))
        .filter(({ colId }) => colId.startsWith(GROUP_COLUMN_PREFIX));
      if (!isEqual(agGroupedColumnSortState, vifGroupedColumnSortState)) {
        setCurrentSortState(vifOrderConfig);
        updateColumnsSort({ api: gridApi, vifOrderConfig, isIndented });
      }
    }

    if (initializeRowStripeStyle && !isEqual(currentRowStripeStyle, initializeRowStripeStyle())) {
      setCurrentRowStripeStyle(initializeRowStripeStyle());
      shouldRefreshGridData = true;
    }

    if (gridApi && !isEqual(vifOrderConfig, currentSortState)) {
      setCurrentSortState(vifOrderConfig);
      updateColumnsSort({ api: gridApi, vifOrderConfig, isIndented });
    }

    if (gridApi) {
      updateOverlayStatus(gridApi);
    }

    if (shouldRefreshGridData) refreshGridData(hardRefresh);
  });

  const fromAddFilter = () => {
    const emptyFilterAdded = !last(vifFilters)?.arguments && filters.length < vifFilters.length;
    const emptyArguments = emptyFilterAdded || emptyFilterRemoved();

    return emptyArguments;
  };

  const emptyFilterRemoved = () => {
    for (let i = 0; i < filters.length; i++) {
      // if removing a filter column with an empty argument, then do not reload
      if (
        !filters[i].arguments &&
        !vifFilters.find((vFilter) => isEqual(vFilter.columns, (filters[i] as SoqlFilter).columns))
      ) {
        return true;
      }
    }
    return false;
  };

  const resetGrandTotalData = useCallback(
    async (grandTotalRow: any, hierarchy: Hierarchy, apiColumns: Column<any>[] | null) => {
      // If column is grouped, setting the total is handled in the autogroup attributes config
      const columnDefs = apiColumns?.map((column) => column.getColDef()) ?? [];
      const firstColumns = columnDefs.filter((column) => column?.rowGroupIndex != null);
      let firstColumn = firstColumns[0];
      if (!firstColumn) {
        firstColumn = columnDefs[0];
        const field = firstColumn?.field ?? '';
        if (
          hierarchy?.showGrandTotal &&
          !get(grandTotalRow, [0, field]) &&
          hierarchy?.columnConfigurations?.filter((v) => v?.aggregation)?.length > 0
        ) {
          set(grandTotalRow, [0, field], I18n.t('shared.visualizations.charts.table.total'));
        }
      }
      if (!isEqual(grandTotalData, grandTotalRow)) {
        setGrandTotalData(grandTotalRow);
      }
    },
    [grandTotalData]
  );

  const refreshGrandTotalData = useCallback(
    async (hierarchy: Hierarchy, apiColumns: Column<any>[] | null) => {
      const grandTotalRow = await getGrandTotalRow(hierarchy);
      resetGrandTotalData(grandTotalRow, hierarchy, apiColumns);
    },
    [getGrandTotalRow, resetGrandTotalData]
  );

  const hierarchy = hierarchyConfig;
  useEffect(() => {
    if (hierarchy && gridApi && hierarchy !== prevHierarchyConfig.current) {
      prevHierarchyConfig.current = hierarchy;
      refreshGrandTotalData(hierarchy, gridApi.getColumns());
    }
  }, [hierarchy]);

  useEffect(() => {
    if (gridApi) {
      // We are creating a GridReadyEvent that is defined in events.d.ts found
      // in the ag-grid-community node_module. This extends AgGridEvent which
      // extends AgEvent which has a required attribute "type" of type string.
      // Since I am not using it at all and have no actual event to pass, I
      // added an arbitrary string.
      handleGridReady({ type: 'gridReady', api: gridApi, context: undefined });
    }
  }, [searchString]);

  useEffect(() => {
    refreshGridData();
  }, [showSubTotal, isIndented]);

  useEffect(() => {
    if (gridApi) {
      if (!agGridOpenNodeLevel) gridApi?.collapseAll();
      else {
        gridApi?.forEachNode(function (node) {
          if (node.group && !node.expanded && node.level < agGridOpenNodeLevel) node.setExpanded(true);
        });
      }
    }
  }, [agGridOpenNodeLevel, gridApi]);

  const [isCustomSortModalOpen, setIsCustomSortModalOpen] = useState<boolean>(false);
  const [selectedColumnsToCustomSort, setSelectedColumnsToCustomSort] = useState<ViewColumn[]>([]);

  const agColumns = useColDefs({
    columns,
    columnMetadata,
    columnFormats,
    datasetUid,
    displayColumnFilters,
    domain,
    hierarchyConfig,
    hierarchyColumns,
    nonStandardAggregations,
    removedHierarchyColumnNames,
    showAgGridColumnAggregations,
    showAgGridColumnMenu,
    useSetFilters,
    vifOrderConfig,
    actionColumnDef,
    showColumnMenuKebab,
    setSelectedColumns: setSelectedColumnsToCustomSort,
    setIsCustomSortModalOpen,
    selectedColumns: selectedColumnsToCustomSort,
    getUniqueValues,
    vifOrderConfigCustomSort: vifOrderConfig
  });

  function handleColumnReorder({ api }: ColumnMovedEvent) {
    onColumnReorder(api.getColumnState());
  }

  function handleGridReady({ api }: GridReadyEvent) {
    setGridApi(api);
    api.setGridOption('serverSideDatasource', datasource);
    if (agFilterModel) {
      const currentFilterModel = api.getFilterModel();
      if (!isEqual(currentFilterModel, agFilterModel)) {
        // if the component user has passed in a stored filter, set it
        // ideally this would be passed in as a prop, maybe in the columnDef
        // but there is no documented way to do this
        api.setFilterModel(agFilterModel);
      }
    }
    if (hierarchy) refreshGrandTotalData(hierarchy, api.getColumns());
  }

  function handleFirstDataRendered({ api }: FirstDataRenderedEvent) {
    updateColumnsSort({ api, vifOrderConfig, isIndented });
    // This does make perfect sense here! Just adjustments to what we actually call here vs already done.
    if (props.printMode) {
      api.collapseAll();
      renderPrintTablePseudoComponent(api);
    }
  }

  // This function updates width of grouped columns when non-indented layout is used.
  // This is necessary because we do not store the actual width of the grouped columns in columnDefs.
  function updateGroupedColumnsWidth(api: GridApi) {
    const groupedColumns = filter(api.getColumnState(), (col) =>
      includes(col.colId, GROUP_COLUMN_PREFIX)
    ).map((col) => col.colId);
    const newWidths = compact(
      groupedColumns.map((colId) => {
        // Get the width from the columns
        const fieldName = colId.replace(`${GROUP_COLUMN_PREFIX}-`, '');
        const originalColumn = filter(columns, (col) => col.fieldName === fieldName)?.[0];
        const width = originalColumn?.width;
        const agGridColumn = api.getColumn(colId);
        if (agGridColumn && width && width !== agGridColumn.getActualWidth()) {
          return {
            key: colId,
            newWidth: width
          };
        }
      })
    );

    if (newWidths.length > 0) {
      api.setColumnWidths(newWidths);
    }
    if (props.printMode) {
      api.sizeColumnsToFit();
    }
  }

  const singleAutoColumnWidth = hierarchyConfig?.singleAutoColumnWidth;
  const autoGroupAttributes = useAutoGroupAttributes({
    datasetUid,
    domain,
    isIndented,
    singleAutoColumnWidth,
    showColumnMenuKebab,
    columns,
    setIsCustomSortModalOpen,
    selectedColumns: selectedColumnsToCustomSort,
    setSelectedColumns: setSelectedColumnsToCustomSort,
    hierarchies: hierarchyColumns,
    getUniqueValues,
    vifOrderConfig: currentSortState,
    columnFormats
  });

  function handleColumnResize({ columns: newColumns, finished }: ColumnResizedEvent) {
    if (finished) {
      onColumnResize(newColumns);
    }
  }

  async function handleColumnVisibilityChange(visChangedEvent: ColumnVisibleEvent) {
    const { api, columns: eventColumns } = visChangedEvent;
    onColumnVisibilityChange(api.getColumnState(), eventColumns, hierarchyConfig?.id);
    if (hierarchyConfig) {
      const grandTotalRow = await getGrandTotalRow(hierarchyConfig);
      resetGrandTotalData(grandTotalRow, hierarchyConfig, api?.getColumns());
    }
  }

  function handleColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) {
    const { api } = event;

    if (!event.columns || !flexibleHierarchiesEnabled) {
      return;
    }
    onColumnRowGroupChange(api.getColumnState(), event.columns, hierarchyConfig?.id);
  }

  function handleColumnValueChanged(event: ColumnValueChangedEvent) {
    const { api } = event;

    if (!event.columns || !flexibleHierarchiesEnabled) {
      return;
    }
    onColumnValueChange(event.columns, hierarchyConfig?.id);
  }

  function handleColumnSort({ api }: SortChangedEvent) {
    onColumnSort(api.getColumnState(), hierarchyConfig?.id);
  }

  function handleModelUpdated(params: ModelUpdatedEvent) {
    const api = params.api;

    updateOverlayStatus(api);
  }

  const getRowStyle = (params: RowClassParams) => {
    return getAgTableRowStyle(params, columnMetadata, initializeRowStripeStyle);
  };

  const headerSortIconHTMLElement = (icon: React.ReactElement) => {
    const headerSortIcon = document.createElement('div');
    ReactDOM.render(
      <ForgeIconButton className="sort-icon">
        <ForgeIcon external external-type="standard" name={icon.props.name} />
        <ForgeTooltip position="bottom" className="sort-tooltip">
          {I18n.t('shared.visualizations.charts.table.header_multisort_helper_text')}
        </ForgeTooltip>
      </ForgeIconButton>,
      headerSortIcon
    );

    return headerSortIcon;
  };

  const columnsSidebarIconHTMLElement = () => {
    const columnsIcon = document.createElement('div');
    ReactDOM.render(<ForgeIcon name="view_column_outline" />, columnsIcon);

    return columnsIcon;
  };

  const headerMenuIconHTMLElement = () => {
    const headerMenuIcon = document.createElement('div');
    ReactDOM.render(
      <ForgeIcon className="agGrid-menu-icon" name="menu" data-testid="ag-header-menu" />,
      headerMenuIcon
    );

    return headerMenuIcon;
  };

  const headerFilterIconHTMLElement = () => {
    const headerFilterIcon = document.createElement('div');
    ReactDOM.render(
      <ForgeIcon className="agGrid-menu-icon" name="filter_menu" data-testid="ag-filter-menu" />,
      headerFilterIcon
    );

    return headerFilterIcon;
  };

  const iconsConfig: { [key: string]: string | '() => HTMLDivElement' } = {
    columns: columnsSidebarIconHTMLElement.bind(this),
    sortAscending: headerSortIconHTMLElement.bind(
      this,
      <ForgeIcon external external-type="standard" name="arrow_upward" />
    ),
    sortDescending: headerSortIconHTMLElement.bind(
      this,
      <ForgeIcon external external-type="standard" name="arrow_downward" />
    )
  };

  if (showAgGridColumnMenu) {
    iconsConfig.menu = headerMenuIconHTMLElement.bind(this);
    iconsConfig.filter = headerFilterIconHTMLElement.bind(this);
  }

  const onAgGridFilterChange = (e: any) => {
    const filterModel = e.api.getFilterModel();
    if (!isEqual(filterModel, agFilterModel)) {
      const f = agGridFilterToVifFilter(filterModel, e.api, datasetUid);
      setFilters(f);
      onFilterChange(f, filterModel);
    }
  };

  const getDefaultColDef: ColDef<any, any> = defaultColDefOverrides
    ? merge(defaultColDef, defaultColDefOverrides)
    : defaultColDef;

  const getMainMenuItems = useCallback((menuItemsParams: GetMainMenuItemsParams): MenuItemDef[] => {
    const columnId = menuItemsParams.column.getColId();
    const metadataForColumn = columnMetadata.find(({ fieldName }) => fieldName === columnId);

    const descriptionContent = DOMPurify.sanitize(
      metadataForColumn?.description ||
        I18n.t('shared.visualizations.charts.table.column_menu.no_description'),
      { ALLOWED_TAGS: [] }
    );

    // Yes, this is gross. AgGrid's devs didn't expect anyone to want to put their own custom elements
    // in the dropdown menu, so we have to sneak 'em in via a menu option's name prop.
    return [
      {
        name: `<b class="column-description-title">${I18n.t(
          'shared.visualizations.charts.table.column_menu.description_label'
        )}</b> ${descriptionContent}`
      }
    ];
  }, []);

  const noRowsOverlay = useMemo(() => {
    return function noRowsOverlayWithProps() {
      return (
        <NoRowsAgGridOverlay
          imageClassName="agGrid-no-rows-overlay-icon"
          textSectionClassName="agGrid-no-rows-overlay-text"
        />
      );
    };
  }, []);

  const processCellForClipboard = (params: ProcessCellForExportParams<any, any>) => {
    const colDef: ColDef = params.column.getColDef();

    const extractAnyAnchorText = (inputString: string) => {
      const anchorRegex = /<a[^>]*>(.*?)<\/a>/g;
      const result = [];
      let match;

      while ((match = anchorRegex.exec(inputString)) !== null) {
        result.push(match[1]);
      }

      // if there is no match we just return the inputString
      return result[0] || inputString;
    };

    // this is similar to how ValueRenderer works
    const extendedParams: ICellRendererParams = { ...params, ...colDef.cellRendererParams, colDef };

    let formattedValue = agGridDataFormatter(
      colDef.cellRendererParams.domain,
      colDef.cellRendererParams.datasetUid,
      columnMetadata,
      nonStandardAggregations,
      extendedParams,
      columnFormats
    );

    // URLs are formatted as anchor elements, so extracting out the visible string with this function if present
    formattedValue = extractAnyAnchorText(formattedValue);

    return formattedValue || params.value;
  };

  const agGridModules = [
    ServerSideRowModelModule,
    RowGroupingModule,
    RangeSelectionModule,
    ClipboardModule,
    SideBarModule,
    ColumnsToolPanelModule,
    SetFilterModule,
    MultiFilterModule,
    ExcelExportModule
  ];

  if (showAgGridColumnMenu) {
    agGridModules.push(MenuModule);
  }

  const mobileView = !isLargeDesktop();

  // We should only create a new instance of the sidebar configuration when the showAgGridAggregations prop changes
  // Otherwise the ag-grid component will re-render unnecessarily
  const sidebarConfiguration = useMemo(() => {
    const result = set(
      sideBarConfig,
      'toolPanels.0.toolPanelParams.suppressRowGroups',
      !showAgGridColumnAggregations
    );
    return set(result, 'toolPanels.0.toolPanelParams.suppressValues', !showAgGridColumnAggregations);
  }, [showAgGridColumnAggregations]);

  const groupedColumns = hierarchyColumns.filter((col) => col.isGrouping).map((col) => col.columnName);

  useDeepCompareEffect(() => {
    agGridContext.current.nonStandardAggregations = nonStandardAggregations;
    agGridContext.current.showSubTotal = showSubTotal;
    agGridContext.current.currentRowStripeStyle = currentRowStripeStyle;
    agGridContext.current.groupedColumns = groupedColumns;
    agGridContext.current.columnFormats = columnFormats;
    agGridContext.current.columnMetadata = columnMetadata;
    agGridContext.current.headerFormat = headerFormat;
    agGridContext.current.linkedAction = linkedAction;
    agGridContext.current.processDiscoveryContext = processDiscoveryContext;
    agGridContext.current.newTableActions = newTableActions;

    gridApi?.refreshCells({ force: true });
    gridApi?.refreshHeader();
  }, [
    columnMetadata,
    columnFormats,
    nonStandardAggregations,
    showSubTotal,
    currentRowStripeStyle,
    groupedColumns,
    headerFormat,
    linkedAction,
    processDiscoveryContext,
    newTableActions
  ]);

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50];
  }, []);

  const columnMenuType = showColumnMenuKebab ? undefined : 'legacy';
  const suppressMenuHideValue = showColumnMenuKebab ? true : mobileView;
  const agGridReactAttributes: AgGridReactProps | AgReactUiProps = {
    defaultColDef: getDefaultColDef,
    enableRangeSelection: true,
    headerHeight: 48,
    rowSelection: 'multiple',
    isServerSideGroupOpenByDefault: (p) =>
      p?.rowNode?.level < agGridOpenNodeLevel || isServerSideGroupOpenByDefault(p),
    context: agGridContext.current,
    modules: agGridModules,
    getRowId: () => {
      return uuid();
    },
    rowModelType: 'serverSide',
    serverSideDatasource: datasource,
    columnDefs: agColumns,
    components: {
      customTooltip: SocrataTooltip
    },
    onColumnMoved: handleColumnReorder,
    onColumnVisible: handleColumnVisibilityChange,
    onColumnRowGroupChanged: handleColumnRowGroupChanged,
    onColumnValueChanged: handleColumnValueChanged,
    onGridReady: handleGridReady,
    onColumnResized: handleColumnResize,
    onFirstDataRendered: handleFirstDataRendered,
    onRowGroupOpened: onRowGroupOpened,
    onSortChanged: handleColumnSort,
    onModelUpdated: handleModelUpdated,
    suppressAggFuncInHeader: true,
    tooltipShowDelay: 0,
    multiSortKey: 'ctrl',
    getRowStyle: getRowStyle,
    autoGroupColumnDef: autoGroupAttributes,
    pagination: true,
    paginationAutoPageSize: !paginationPageSize,
    paginationPageSize: paginationPageSize,
    pinnedBottomRowData: hierarchy?.showGrandTotal ? grandTotalData : undefined,
    groupDisplayType: isIndented ? 'singleColumn' : 'multipleColumns',
    getMainMenuItems: showAgGridColumnMenu ? getMainMenuItems : undefined,
    suppressDragLeaveHidesColumns: true,
    icons: iconsConfig,
    sideBar: sidebarConfiguration,
    suppressMenuHide: suppressMenuHideValue,
    onFilterChanged: onAgGridFilterChange,
    rowBuffer: 20,
    getLocaleText: (key) => {
      if (key.key == 'group' && isIndented) {
        const rowGroups = key.api.getRowGroupColumns();
        return rowGroups[0].getColDef().headerName;
      }

      if (key.variableValues) {
        return I18n.t(key.key, { scope: 'common.ag_grid_react', variable: key.variableValues });
      }

      return I18n.t(key.key, { scope: 'common.ag_grid_react' });
    },
    groupTotalRow: showSubTotal ? 'bottom' : undefined,
    suppressRowHoverHighlight: true,
    noRowsOverlayComponent: noRowsOverlay,
    paginationPageSizeSelector: paginationPageSizeSelector,
    columnMenu: columnMenuType,
    paginateChildRows: initiatePrintMode(),
    showOpenedGroup: initiatePrintMode(),
    processCellForClipboard: processCellForClipboard,
    suppressCsvExport: true, // we use custom export, so don't use ag-grids version
    suppressCutToClipboard: true, // we don't currently use these so hide them
    tooltipMouseTrack: true,
    onCellFocused: (params) => {
      if (!params.api || !params.column) return;

      const focusedCell = document.querySelector('.ag-cell-focus');
      if (focusedCell) {
        // Get cell position
        const rect = focusedCell.getBoundingClientRect();

        // Create event with the cell's position
        focusedCell.dispatchEvent(
          new MouseEvent('mouseenter', {
            bubbles: true,
            cancelable: true,
            clientX: rect.right,
            clientY: rect.bottom
          })
        );
      }
    },
    pivotMode: false, // PivotMode should start false, user must toggle sidebar button
    pivotPanelShow: 'always', // As soon as button is toggled, show the top pivot panel
    serverSidePivotResultFieldSeparator: '@@' // Magic separator keyword, using @@ is just familiar to me.
  };

  //Create a ReportMetadata object for passing to the PdfPreviewModal
  //TODO: This pulls from the visualization. We should eventually pull this up to the report level
  const reportMetadata: ReportMetadata = {
    title: title || '',
    description: description || '',
    //Use the current time, formatted for a human, for the generated date
    generatedDate: moment().format(TIME_FORMATS.date_time_24h),
    reportUrl: window.location.href,
    //TODO: Pull from the report. Putting placeholders here for now.
    author: get(getCurrentUser(), 'displayName', ''),
    environment: window.location.host,
    status: 'final',
    filters: vifFilters,
    vifParameterOverrides
  };

  const exportProps: IExportGridParams = useMemo(() => {
    return {
      domain,
      datasetUid,
      hierarchyConfig,
      columns,
      removedHierarchyColumnNames,
      hierarchyColumns,
      vifColumns,
      datasetName,
      getExportData,
      activeHierarchyId,
      vizUid,
      agGridContext,
      gridApi,
      headerFormat,
      agGridReactAttributes,
      vifOrderConfig: currentSortState
    };
  }, [
    domain,
    datasetUid,
    hierarchyConfig,
    columns,
    removedHierarchyColumnNames,
    hierarchyColumns,
    vifColumns,
    datasetName,
    getExportData,
    activeHierarchyId,
    vizUid,
    agGridContext,
    gridApi,
    headerFormat,
    agGridReactAttributes,
    currentSortState
  ]);

  return (
    <>
      <AgGridReact {...agGridReactAttributes} />
      <ExportGrid {...exportProps} />
      {isPrintModalOpen && (
        <PdfPreviewModal
          dataset={{ domain, datasetUid }}
          getExportData={getExportData}
          gridApi={gridApi}
          showSubTotal={showSubTotal}
          showTotal={!isEmpty(grandTotalData)}
          onClose={onPrintModalClose}
          columnFormats={columnFormats}
          nonStandardAggregations={nonStandardAggregations}
          reportMetadata={reportMetadata}
          vifOrderConfig={vifOrderConfig}
        />
      )}
    </>
  );
};
export default Grid;
