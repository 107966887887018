import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import I18n from 'common/i18n';

import MetadataProvider from '../../dataProviders/MetadataProvider';
import { BaseVisualization } from './types';
import { SourceDataOptions, SourceDataOptionsProps } from './SourceDataOptions';
import { isMapVisualization, isTableVisualization } from 'common/visualizations/helpers/VifSelectors';

import { FeatureFlags } from 'common/feature_flags';

/**
 * Renders InfoContainer
 * - Show source link
 */

const shouldRenderExportDataOptions = (viz: BaseVisualization) =>
  !viz.isMeasure() &&
  viz.isInStory() &&
  ((isTableVisualization(viz.getVif()) &&
    !viz.isSummaryTableVisualization() &&
    FeatureFlags.value('export_data_for_tables_in_stories')) ||
    (!isTableVisualization(viz.getVif()) && FeatureFlags.value('enable_export_data_for_viz_in_stories')));

export function template(): JQuery {
  return $('<div>', { class: 'socrata-visualization-info' }).append(
    $('<div>', { class: 'socrata-visualization-view-source-data' })
  );
}

export const renderReact = (viz: BaseVisualization, additionalProps?: Partial<SourceDataOptionsProps>) => {
  const viewSourceContainer = viz.$element.find('.socrata-visualization-view-source-data').last();
  const showExportDataOption = shouldRenderExportDataOptions(viz);
  const showViewSourceDataLink = get(viz.getVif(), 'configuration.viewSourceDataLink', true);

  /**
   * EN-51751: This is needed for a weird edge case where the source data is hidden but you can still export the underlying data.
   * We rely on these classes on the viz container to pass in the right height. Without the classes, the heights would render
   * incorrectly. This can be removed once we do the viz forgification refactor
   */
  if (shouldRenderExportDataOptions(viz)) {
    const container = viz.$container;
    container.addClass('socrata-visualization-export-source-data');
  }

  if (viewSourceContainer.length) {
    ReactDOM.render(
      <SourceDataOptions
        vif={viz.getVif()}
        viz={viz}
        seriesIndex={viz.getSeriesIndex()}
        {...additionalProps}
        showExportDataOption={showExportDataOption}
        showViewSourceDataLink={
          FeatureFlags.value('enable_view_source_data_stories') && showViewSourceDataLink
        }
        vizUid={viz.$container?.attr('id')}
      />,
      viewSourceContainer[0]
    );
  }
};

export const onUpdateVif = (self: BaseVisualization) => {
  const shouldRenderViewSourceDataLink = get(self.getVif(), 'configuration.viewSourceDataLink', true);

  // TODO: EN-48481 the first else if is a stopgap fix for EN-48386
  // all table viz in stories need info container to have a functioning bottom dragger
  if (shouldRenderViewSourceDataLink) {
    showViewSourceDataLink(self);
  } else if (shouldRenderExportDataOptions(self)) {
    showInfoContainerOnly(self);
  } else {
    hideViewSourceDataLink(self);
  }
};

export const hideInfo = (self: BaseVisualization) => {
  const safeToHide = !self.panningNoticeVisible && !self.includeDataLinkHeight && !self.viewTabsVisible;

  if (safeToHide) {
    self.$container.removeClass('socrata-visualization-info forge-style-socrata-visualization-info');
  }
};

export const showInfo = (self: BaseVisualization) => {
  const safeToShow = self.panningNoticeVisible || self.includeDataLinkHeight || self.viewTabsVisible;

  // It's weird that the show function toggles this (and only this) class, while the hide only ever removes...
  self.$container.toggleClass('socrata-visualization-info', safeToShow);

  if (safeToShow) {
    self.$container.addClass('forge-style-socrata-visualization-info');
  }
};

export const showViewSourceDataLink = async (self: BaseVisualization) => {
  const viewSourceDataOverride = get(self.getVif(), 'origin.url');
  const seriesIndex = self.getSeriesIndex();
  const renderLink = (href: string) => {
    if (self.isEmbedded()) {
      href += '?referrer=embed';
    }

    const container = self.$container;
    container.addClass('socrata-visualization-view-source-data');

    renderReact(self, {
      viewSourceLinkLabel: I18n.t('shared.visualizations.charts.common.more_actions'),
      viewSourceLinkHref: href,
      seriesIndex
    });

    if (shouldRenderExportDataOptions(self)) {
      container.addClass('socrata-visualization-export-source-data');
    }

    // Add the info class immediately so that visualizations can accurately
    // measure how much space they have to fill, but only add the
    // view-source-data class to show the link once the optional metadata
    // request has returned, if it is made.
    self.includeDataLinkHeight = false;
    showInfo(self);
    triggerMapResizeIfNewGLMaps(self);
  };

  // Reserve space before waiting for async queries below
  // So visualizations don't measure render area prematurely
  // We had race condition between attribution domain and table page size
  showInfo(self);
  if (!isEmpty(viewSourceDataOverride)) {
    renderLink(viewSourceDataOverride);
  } else if (get(self.getVif(), `series[${seriesIndex}].dataSource.type`) === 'socrata.soql') {
    const domain = get(self.getVif(), `series[${seriesIndex}].dataSource.domain`);
    const datasetUid = get(self.getVif(), `series[${seriesIndex}].dataSource.datasetUid`);
    const metadataProvider = new MetadataProvider(
      {
        datasetUid,
        ...(domain && { domain })
      },
      true
    );
    const datasetMetadataAndFederationStatus = await metadataProvider.getDatasetMetadataAndFederationStatus();
    const attributionDomain = await metadataProvider.getAttributionDomain(datasetMetadataAndFederationStatus);

    if (attributionDomain) {
      renderLink(`https://${attributionDomain}/d/${datasetUid}`);
    } else {
      hideInfo(self);
    }
  }
};

export const hideViewSourceDataLink = (self: BaseVisualization) => {
  if (get(self.getVif(), 'series[0].dataSource.type') === 'socrata.soql') {
    self.$container.removeClass('socrata-visualization-view-source-data');
    renderReact(self);
    self.includeDataLinkHeight = false;
    // with summary table, we still need to show info container to work properly!
    showInfo(self);
    triggerMapResizeIfNewGLMaps(self);
  }
};

const showInfoContainerOnly = (self: BaseVisualization) => {
  if (get(self.getVif(), 'series[0].dataSource.type') === 'socrata.soql') {
    renderReact(self);
    self.includeDataLinkHeight = true;
    showInfo(self);
  }
};

const triggerMapResizeIfNewGLMaps = (self: BaseVisualization) => {
  if (isMapVisualization(self.getVif())) {
    // resize the map canvas to fit to it's container height
    self.$container.trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  }
};
