import React, { useState, useRef, useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { View } from 'common/types/view';
import {
  getProcessDiscoveryContext,
  getPrimaryKeyValue
} from 'common/visualizations/views/agGridReact/helpers/processDiscoveryHelper';
import { ForgePopup, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';
import ProcessDiscoveryContent from './ProcessDiscoveryContent';
interface Props {
  rowParams: ICellRendererParams;
  metadata: View;
  newTableActions: string[];
}

const ProcessDiscoveryViewButton: React.FC<Props> = ({ rowParams, metadata, newTableActions }) => {
  const contextParams = useMemo(() => getProcessDiscoveryContext(metadata), [metadata]);
  const { primaryKeyColumn } = contextParams;
  const primaryKeyValue = useMemo(() => getPrimaryKeyValue(rowParams, primaryKeyColumn), [rowParams]);

  const targetRef = useRef<any>();

  const [showPopUp, togglePopup] = useState<boolean>(false);

  return (
    <div ref={targetRef} className="process-discovery-view">
      <ForgeIconButton>
        <button
          onClick={() => togglePopup(!showPopUp)}
          type="button"
          data-testid="process-action-button-id"
          className="tyler-icons"
          aria-label="process-discovery-view-button"
        >
          <ForgeIcon name="more_vert" />
        </button>
      </ForgeIconButton>
      {showPopUp ? (
        <ForgePopup targetElementRef={targetRef} open={showPopUp} onDismiss={() => togglePopup(false)}>
          <div className="process-discovery-popup">
            <ProcessDiscoveryContent
              primaryKeyValue={primaryKeyValue}
              contextParams={contextParams}
              newTableActions={newTableActions}
            />
          </div>
        </ForgePopup>
      ) : null}
    </div>
  );
};

export default ProcessDiscoveryViewButton;
