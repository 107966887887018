import { GetMainMenuItemsParams, IMenuActionParams, MenuItemDef } from '@ag-grid-community/core';
import I18n from 'common/i18n';
import { ForgeIcon } from '@tylertech/forge-react';
import ReactDOM from 'react-dom';
import React from 'react';
import { ViewColumn } from 'common/types/viewColumn';
import { HierarchyColumnConfig, OrderConfig } from 'common/visualizations/vif';
import eventBus from './EventBus';
import { UniqueValuesData } from '../types';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';

interface IGetCustomSortMenuItems {
  getMainMenuParams: GetMainMenuItemsParams;
  mainMenuItems?: (string | MenuItemDef)[];
  columns?: ViewColumn[];
  setIsCustomSortModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  hierarchies?: HierarchyColumnConfig[];
  selectedColumns?: ViewColumn[];
  setSelectedColumns?: React.Dispatch<React.SetStateAction<ViewColumn[]>>;
  getUniqueValues?: (columns: ViewColumn[], order: OrderConfig[]) => Promise<UniqueValuesData>;
  vifOrderConfig?: OrderConfig[];
  columnFormats: { [key: string]: TableColumnFormat };
}

const isIndentedHierarchy = (clickedColumnId: string) => {
  // Columns of indented hierarchies only contain the following column id
  return clickedColumnId === 'ag-grid-autocolumn';
};

const isUnindentedHierarchy = (clickedColumnId: string) => {
  // Columns of unindented hierarchies includes the following column id
  // e.g. `ag-grid-autocolumn-number` or `ag-grid-autocolumn-money`
  return clickedColumnId.includes('ag-grid-autocolumn-');
};

const getHierachiesAsViewColumns = (
  columns: ViewColumn[],
  hierarchies: HierarchyColumnConfig[]
): ViewColumn[] => {
  return hierarchies
    .map((hierarchy) => columns.find((column) => column.fieldName === hierarchy.columnName))
    .filter((column): column is ViewColumn => column !== undefined);
};

const getSelectedViewColumns = (columns: ViewColumn[], clickedColumnId: string) => {
  return columns.filter((column) => column.fieldName.toLocaleLowerCase() === clickedColumnId);
};

const getColumnsToCustomSort = (
  columns: ViewColumn[],
  hierarchies: HierarchyColumnConfig[],
  clickedColumnId: string
) => {
  if (isIndentedHierarchy(clickedColumnId)) {
    return getHierachiesAsViewColumns(columns, hierarchies);
  }

  if (isUnindentedHierarchy(clickedColumnId)) {
    clickedColumnId = clickedColumnId.split('ag-grid-autocolumn-')[1];
  }

  return getSelectedViewColumns(columns, clickedColumnId);
};

const getCustomSortMenuItems = ({
  getMainMenuParams,
  mainMenuItems = [],
  columns,
  hierarchies,
  selectedColumns,
  setIsCustomSortModalOpen,
  setSelectedColumns,
  getUniqueValues,
  vifOrderConfig,
  columnFormats
}: IGetCustomSortMenuItems) => {
  if (mainMenuItems === undefined || mainMenuItems?.length > 0) mainMenuItems = [];
  const itemsToInclude = ['sortAscending', 'sortDescending'];
  getMainMenuParams.defaultItems.forEach((item) => {
    if (itemsToInclude.indexOf(item) >= 0) {
      mainMenuItems.push(item);
    }
  });
  mainMenuItems.push({
    name: I18n.t('common.ag_grid_react.customSort'),
    action: (params: IMenuActionParams) => {
      if (
        !columns ||
        hierarchies === undefined ||
        !selectedColumns ||
        !setIsCustomSortModalOpen ||
        !params.column ||
        !setSelectedColumns ||
        !vifOrderConfig
      ) {
        return;
      }

      hierarchies = hierarchies.filter((hierarchy) => hierarchy.isGrouping);
      const clickedColumnId: string = params.column.getColId().toLocaleLowerCase();
      const columnsToColumnSort = getColumnsToCustomSort(columns, hierarchies, clickedColumnId);
      eventBus.dispatch('customSort', {
        columnsToSort: columnsToColumnSort,
        columnFormats: columnFormats,
        vifOrderConfig: vifOrderConfig,
        getUniqueValues: getUniqueValues
      });
    },
    icon: (() => {
      const container = document.createElement('div');
      ReactDOM.render(<ForgeIcon external external-type="standard" name="swap_vert" />, container);
      return container;
    })()
  });

  return mainMenuItems;
};

export default getCustomSortMenuItems;
